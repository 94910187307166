import { PUBLIC_SENTRY_DSN, PUBLIC_ENVIRONMENT_NAME } from '$env/static/public';
import { init, Replay } from '@sentry/sveltekit';

export function sentryInitClient() {
  return init({
    dsn: PUBLIC_SENTRY_DSN,
    environment: PUBLIC_ENVIRONMENT_NAME,
    tracesSampleRate: 1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1,
    integrations: [new Replay()],
  });
}
